svg.contour-editor {

  text.start {
    text-anchor: middle;
    dominant-baseline: hanging;
  }

  text.finish {
    text-anchor: middle;
    //dominant-baseline: text-bottom;
  }

  .base-line {
    stroke: #ddd;
    stroke-width: 1;
    stroke-dasharray: 4;
    vector-effect: non-scaling-stroke;

  }

  .ruler-base {
    stroke: #ddd;
    stroke-width: 1;
    vector-effect: non-scaling-stroke;
  }

  .ruler-tick {
    stroke: #ddd;
    stroke-width: 1;
    vector-effect: non-scaling-stroke;
  }

  .ruler-tick-label {
    fill: #ddd;
    text-anchor: end;
    dominant-baseline: middle;
  }

  .point {
    fill: #222;
  }

  .line {
    fill: none;
    stroke: #222;
    stroke-width: 2;
    vector-effect: non-scaling-stroke;
  }
}