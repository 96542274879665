.contour-view {

  .grid-dot {
    fill: none;
    stroke: #111;
    stroke-width: 1;
  }

  .contour-line {
    stroke: #000;
    fill: none;
    stroke-width: 1;
  }
}
